import styled from 'styled-components';

export const Transition2 = styled.div`
  ${({ start, speed }) => {
    switch (start) {
      case 'slide-up':
        return `
        transition: ${speed ?? 1}s cubic-bezier(0, 1, 0.5, 1);
        transform: translateY(100%);
      `;
      default:
        return ``;
    }
  }}

  &.slide-up {
    transform: translateY(0);
  }
`;

export const Transition = styled.div`
  background-color: hsla(0, 0%, 100%, 0.95);
  border: 1px solid #ccc;
  border-bottom: 0;
  text-align: center;
  border-radius: 10px 10px 0 0;
  padding: 25px 25px 25px 25px;
  color: #000;

  ${({ backgroundColor, textColor }) => {
    return `
      ${backgroundColor && `background-color: ${backgroundColor}`};
      ${textColor && `color: ${textColor};`};
    `;
  }}

  ${({ start, speed }) => {
    switch (start) {
      case 'slide-up':
        return `
          transition: ${speed ?? 1}s cubic-bezier(0, 1, 0.5, 1);
          transform: translateY(100%);
        `;
      default:
        return ``;
    }
  }}

  &.slide-up {
    transform: translateY(0);
  }

  @media (max-width: 500px) {
    padding: 25px 20px 10px 20px;
  }
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 35px;
  z-index: 8000;
  font-size: 19px;
  max-width: 315px;

  line-height: 1.6;
  &.hidden {
    display: none;
  }
  &.left {
    right: unset;
    left: 35px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
    max-width: 220px;
  }
`;

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
  position: absolute;
  z-index: 1;
  &:after {
    content: ' \\2715';
  }
  &:hover {
    cursor: pointer;
    color: red;
  }
`;
